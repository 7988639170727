import "./App.css";
import "./assets/vendor/bbootstrap/css/bootstrap.min.css";
import "./assets/vendor/bootstrap-icons/bootstrap-icons.css";
import "./assets/vendor/glightbox/css/glightbox.min.css";
import "./assets/vendor/swiper/swiper-bundle.min.css";
import "./assets/vendor/remixicon/remixicon.css";
import "./assets/css/main.css";
import Home from "./home";
import { BrowserRouter as Router } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Route, Switch } from "react-router-dom";
import Services from "./services";
import { ContactUs } from "./contactus";
import { About } from "./about/about";
import { Training } from "./trainings/training";
import { Publications } from "./publications/publication";
import { PublicationTypeContent } from "./publications/publicationtypecontent";
import ServicePage from "./services/oneservicepage";
import { PageNotFound } from "./components/PageNotFound";
import {
  AdminHome,
  AdminHomeAchievements,
  AdminHomeAchievementsTitle,
  Partners,
} from "./admin/home/AdminHomeComponentsList";
import isEmpty from "lodash/isEmpty";
import { store } from "./store/store";
import { authLogout, authSuccess } from "./store/actions/auth";
import jwtDecode from "jwt-decode";
import "react-notifications-component/dist/theme.css";
import {
  AdminBDFBackground,
  AdminBDFMission,
  AdminBDFValue,
  AdminBDFVision,
  AdminExecutive,
} from "./admin/about/AdminAboutComponentsList";
import {
  AdminHomeAchievementsList,
  AdminHomeAchievementTitlessList,
  AdminHomeList,
  PartnersList,
} from "./admin/home/AdminHomeComponentsTables";
import {
  AdminBackgroundList,
  AdminBDFBackgroundList,
  AdminBDFMissionList,
  AdminBDFValuesList,
  AdminBDFVisionsList,
  AdminExecutivesList,
} from "./admin/about/AdminAboutComponentsTables";
import { AdminContactUsList } from "./admin/contactus/AdminContactUsTables";
import { AdminContactUs } from "./admin/contactus/AdminContactUsComponentsList";
import {
  AdminLanguages,
  AdminLanguagesList,
  AdminLinks,
  AdminLinksList,
  AdminUserProfile,
  AdminUsers,
  AdminUsersList,
} from "./admin/utiltities/AdminLanguage";
import {
  AdminTrainingCategories,
  AdminTrainingCategoriesComponents,
  AdminTrainingCategoriesComponentsNew,
  AdminTrainingIntro,
} from "./admin/training/TrainingComponentsList";
import {
  AdminTrainingCategoriessList,
  AdminTrainingIntrosList,
} from "./admin/training/TrainingComponentsTables";
import {
  AdminPublicationContents,
  AdminPublicationContentsNew,
  AdminPublications,
  AdminTextTestimonies,
  AdminYoutubeTestimonies,
} from "./admin/publications/AdminPublicationComponentsList";
import {
  AdminPublicationsList,
  AdminTextTestimoniesList,
  AdminYoutubeTestimoniesList,
} from "./admin/publications/AdminPublicationComponentsTables";
import {
  AdminServiceRequirementCriteria,
  AdminServiceRequirementCriteriaNew,
  AdminServiceRequirementCriteriasValuesNew,
  AdminServiceRequirementCriteriaValues,
  AdminServiceRequirements,
  AdminServiceRequirementsNew,
  AdminServices,
} from "./admin/services/AdminServicesComponentsList";
import { AdminServicesList } from "./admin/services/AdminServicesComponentsTables";
import { MiModal } from "./admin/components/MiModal";
import { useSelector } from "react-redux";
import { AdminLoginPage } from "./admin/login";
import { AdminSMS, AdminSMSsList } from "./admin/sms/AdminSMSComponentsList";

import React, { useEffect } from "react";
import ReactGA from "react-ga4"; // Import react-ga4

const token = localStorage.jwt;

(async () => {
  try {
    if (isEmpty(token)) {
      store.dispatch(authLogout());
      return;
    }

    //decode token
    const decodedUser = jwtDecode(token);
    if (decodedUser) {
      store.dispatch(authSuccess({ token: token, user: decodedUser }));
    }
  } catch (err) {
    store.dispatch(authLogout());
    window.location.reload();
    return;
  }
})();

const App = () => {
  const isAuthenticated = useSelector((state) => state.auth.token !== null);

  useEffect(() => {
    // Initialize Google Analytics with your Stream ID
    ReactGA.initialize("G-82YGB9313T"); // Replace with your Stream ID

    // Track the first page view when the app loads
    ReactGA.send("pageview", { page_path: window.location.pathname });
  }, [window.location.pathname]); // Send pageview data

  let routes = (
    <Switch>
      <Route exact path="/">
        <Home />
      </Route>
      <Route exact path="/about">
        <About />
      </Route>
      <Route exact path="/services">
        <Services />
      </Route>
      <Route exact path="/publications">
        <Publications />
      </Route>
      <Route exact path="/publication-type/:objectId?">
        <PublicationTypeContent />
      </Route>
      <Route exact path="/service-page/:id?">
        <ServicePage />
      </Route>
      <Route exact path="/training">
        <Training />
      </Route>
      <Route exact path="/contactus">
        <ContactUs />
      </Route>
      <Route exact path="/sign-in">
        <AdminLoginPage />
      </Route>
      <Route>
        <PageNotFound />
      </Route>
    </Switch>
  );
  if (isAuthenticated) {
    routes = (
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route exact path="/about">
          <About />
        </Route>
        <Route exact path="/services">
          <Services />
        </Route>
        <Route exact path="/publications">
          <Publications />
        </Route>
        <Route exact path="/publication-type/:objectId?">
          <PublicationTypeContent />
        </Route>
        <Route exact path="/service-page/:id?">
          <ServicePage />
        </Route>
        <Route exact path="/training">
          <Training />
        </Route>
        <Route exact path="/contactus">
          <ContactUs />
        </Route>
        <Route exact path="/sign-in">
          <AdminLoginPage />
        </Route>
        <Route exact path="/admin">
          <AdminHome />
        </Route>
        <Route exact path="/admin/home/:objectId?">
          <AdminHome />
        </Route>{" "}
        <Route exact path="/admin/home/objects/list">
          <AdminHomeList />
        </Route>
        <Route exact path="/admin/home-achievements/:objectId?">
          <AdminHomeAchievementsTitle />
        </Route>
        <Route exact path="/admin/home-achievements/objects/list">
          <AdminHomeAchievementTitlessList />
        </Route>
        <Route exact path="/admin/home-achievements-list/:objectId?">
          <AdminHomeAchievements />
        </Route>
        <Route exact path="/admin/home-achievements-list/objects/list">
          <AdminHomeAchievementsList />
        </Route>
        <Route exact path="/admin/partners/:objectId?">
          <Partners />
        </Route>
        <Route exact path="/admin/partners/objects/list">
          <PartnersList />
        </Route>
        <Route exact path="/admin/about/bdf-background/:objectId?">
          <AdminBDFBackground />
        </Route>
        <Route exact path="/admin/about/bdf-background/objects/list">
          <AdminBDFBackgroundList />
        </Route>
        <Route exact path="/admin/about/executives/:objectId?">
          <AdminExecutive />
        </Route>
        <Route exact path="/admin/about/executives/objects/list">
          <AdminExecutivesList />
        </Route>
        <Route exact path="/admin/about/missions/:objectId?">
          <AdminBDFMission />
        </Route>
        <Route exact path="/admin/about/missions/objects/list">
          <AdminBDFMissionList />
        </Route>
        <Route exact path="/admin/about/visions/:objectId?">
          <AdminBDFVision />
        </Route>
        <Route exact path="/admin/about/visions/objects/list">
          <AdminBDFVisionsList />
        </Route>
        <Route exact path="/admin/about/values/:objectId?">
          <AdminBDFValue />
        </Route>
        <Route exact path="/admin/about/values/objects/list">
          <AdminBDFValuesList />
        </Route>
        <Route exact path="/admin/contactus/:objectId?">
          <AdminContactUs />
        </Route>
        <Route exact path="/admin/contactus/objects/list">
          <AdminContactUsList />
        </Route>
        <Route exact path="/admin/languages/:objectId?">
          <AdminLanguages />
        </Route>
        <Route exact path="/admin/languages/objects/list">
          <AdminLanguagesList />
        </Route>
        <Route exact path="/admin/trainings-intros/:objectId?">
          <AdminTrainingIntro />
        </Route>
        <Route exact path="/admin/trainings-intros/objects/list">
          <AdminTrainingIntrosList />
        </Route>
        <Route exact path="/admin/trainings-categories/:objectId?">
          <AdminTrainingCategories />
        </Route>
        <Route exact path="/admin/trainings-categories/objects/list">
          <AdminTrainingCategoriessList />
        </Route>
        <Route exact path="/admin/trainings-categories-components/:objectId?">
          <AdminTrainingCategoriesComponents />
        </Route>
        <Route
          exact
          path="/admin/trainings-categories-components-new/:parentId?/:objectId?"
        >
          <AdminTrainingCategoriesComponentsNew />
        </Route>
        <Route exact path="/admin/publications/:objectId?">
          <AdminPublications />
        </Route>
        <Route exact path="/admin/publications/objects/list">
          <AdminPublicationsList />
        </Route>
        <Route exact path="/admin/publications-contents/:objectId?">
          <AdminPublicationContents />
        </Route>
        <Route
          exact
          path="/admin/publications-contents-new/:parentId?/:objectId?"
        >
          <AdminPublicationContentsNew />
        </Route>
        <Route exact path="/admin/text-testimonies/:objectId?">
          <AdminTextTestimonies />
        </Route>
        <Route exact path="/admin/text-testimonies/objects/list">
          <AdminTextTestimoniesList />
        </Route>
        <Route exact path="/admin/youtube-testimonies/:objectId?">
          <AdminYoutubeTestimonies />
        </Route>
        <Route exact path="/admin/youtube-testimonies/objects/list">
          <AdminYoutubeTestimoniesList />
        </Route>
        <Route exact path="/admin/services/:objectId?">
          <AdminServices />
        </Route>
        <Route exact path="/admin/services/objects/list">
          <AdminServicesList />
        </Route>
        <Route exact path="/admin/services-requirements/:objectId?">
          <AdminServiceRequirements />
        </Route>
        <Route exact path="/admin/services-requirements-criterias/:objectId?">
          <AdminServiceRequirementCriteria />
        </Route>
        <Route
          exact
          path="/admin/services-requirements-criterias-values/:objectId?"
        >
          <AdminServiceRequirementCriteriaValues />
        </Route>
        <Route
          exact
          path="/admin/services-requirements-new/:parentId?/:objectId?"
        >
          <AdminServiceRequirementsNew />
        </Route>
        <Route
          exact
          path="/admin/services-requirements-criterias-new/:parentId?/:objectId?"
        >
          <AdminServiceRequirementCriteriaNew />
        </Route>
        <Route
          exact
          path="/admin/services-requirements-criterias-values-new/:parentId?/:objectId?"
        >
          <AdminServiceRequirementCriteriasValuesNew />
        </Route>
        <Route exact path="/admin/users/:objectId?">
          <AdminUsers />
        </Route>
        <Route exact path="/admin/users/objects/list">
          <AdminUsersList />
        </Route>
        <Route exact path="/admin/profile">
          <AdminUserProfile />
        </Route>
        <Route exact path="/modal">
          <MiModal />
        </Route>
        <Route exact path="/admin/links/:objectId?">
          <AdminLinks />
        </Route>
        <Route exact path="/admin/links/objects/list">
          <AdminLinksList />
        </Route>
        <Route exact path="/sms-module">
          <AdminSMS />
        </Route>
        <Route exact path="/sms-module/objects/list">
          <AdminSMSsList />
        </Route>
        <Route>
          <PageNotFound />
        </Route>
      </Switch>
    );
  }
  return (
    <>
      <Helmet>
        <title>BDF - Business Development Fund</title>
        <meta name="description" content="BDF description BDF Rwanda" />
        <meta
          name="keywords"
          content="funding, guarantee, leasing, agri business, Access to finance, Access to finance for SMEs, Access to finance for entrepreneurs,
          Access to finance Rwanda, SME, Credit guarantee scheme, Bank guarantee letter, Microloans,
          Small business loans, Business financing, Types of business financing"
        />
        <link rel="icon" href="/logo512.png" />
      </Helmet>
      <Router>{routes}</Router>
    </>
  );
};

export default App;
