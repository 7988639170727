import { useTranslation } from "react-i18next";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import { useState } from "react";

export const TeamMember = (props) => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const handleClose = (e) => {
    e.preventDefault();
    setShow(false);
  };
  let levelClass = "col-lg-3 col-md-3";
  if (props?.member?.level == "executive") {
    levelClass = "col-lg-4 col-md-4";
  }
  return (
    <div
      className={
        levelClass + " service-item d-flex flex-column shadow-sm py-3 me-3"
      }
      data-aos="fade-up"
      data-aos-delay="100"
    >
      <Modal show={show} onHide={handleClose} size="xl">
        <Modal.Body>
          <div className="row px-3 pt-5 pb-2">
            <div className="col-md-4">
              <img
                src={`${process.env.REACT_APP_BACKEND_URL}/${props?.member?.image}`}
                className="img-fluid shadow"
                alt=""
              />
            </div>
            <div className="col-md-8 px-5">
              <h4 className="bdf-bluefontcolor  font-bold">
                {props?.member?.name}
              </h4>
              <h6 className="bdf-bluefontcolor  font-bold ">
                {props?.member?.title}
              </h6>
              <p className="description pt-3">{props?.member?.profile}</p>
              <button className="mi-btn" onClick={handleClose}>
                Close
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <div
        className="team-member h-100"
        onClick={(e) => {
          e.preventDefault();
          setShow(true);
        }}
      >
        <div className="member-img w-100">
          <img
            src={`${process.env.REACT_APP_BACKEND_URL}/${props?.member?.image}`}
            className="img-fluid max-width:100% h-auto"
            alt=""
          />
        </div>
        <div className="member-info pt-3">
          <h4 className="bdf-bluefontcolor-title">{props?.member?.name}</h4>
          <span>{props?.member?.title}</span>
        </div>
      </div>
    </div>
  );
};
