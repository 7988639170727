import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ScrollToTop } from "../components/backtotop";
import { Footer } from "../components/footer";
import Header from "../header";
import { TeamMemberSwipper } from "./swipper";
import { TeamMember } from "./team";
import ceo from "../images/mgt/ceo.png";
import dceo from "../images/mgt/dceo.png";
import monique from "../images/mgt/Monique.png";
import betty from "../images/mgt/Betty.png";
import benoit from "../images/mgt/Benoit.png";
import { NavLink } from "react-router-dom";
import carine from "../images/mgt/Carine.png";
import egide from "../images/mgt/Egide.png";
import elie from "../images/mgt/Elie.png";
import john from "../images/mgt/Kagarama.png";
import beata from "../images/mgt/beata.png";
import materne from "../images/mgt/Matty.jpg";
import { PartnerCard, PartnersSection } from "./partners";
import * as actions from "../store/actions/";
import { useDispatch, useSelector } from "react-redux";
import parse from "html-react-parser";

export const About = function (props) {
  const dispatch = useDispatch();
  const [showSMM, setShowSMM] = useState(1);
  const [showBoD, setShowBoD] = useState(0);
  const language = useSelector((state) => state.homepage.language);
  const background = useSelector((state) => state.about.background);
  const misVisValue = useSelector((state) => state.about.misVisValue);
  const smExecutives = useSelector((state) => state.about.smExecutives);
  const smManagers = useSelector((state) => state.about.smManagers);
  const bdExecutives = useSelector((state) => state.about.bdExecutives);
  const bdManagers = useSelector((state) => state.about.bdManagers);
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(actions.getActiveBackgrounds(language));
    dispatch(actions.getActiveMissionVisionValues(language));
    dispatch(actions.getActiveSMExecutives(language));
    dispatch(actions.getActiveSMManagers(language));
    dispatch(actions.getActiveBDManagers(language));
    dispatch(actions.getActiveBDExecutives(language));
  }, [language]);
  return (
    <body className="page-about">
      <Header />
      <main id="main">
        <div className="breadcrumbs d-flex align-items-center about-header-bg">
          <div className="container position-relative d-flex flex-column align-items-center">
            <h2>{t("About")}</h2>
          </div>
        </div>
        <section id="about" className="about">
          <div className="container" data-aos="fade-up">
            <div className="row gy-4" data-aos="fade-up">
              <div className="col-lg-5 d-flex align-items-center">
                <img
                  src={`${process.env.REACT_APP_BACKEND_URL}/${background?.image}`}
                  className="img-fluid"
                  alt=""
                />
              </div>
              <div className="col-lg-7">
                <div className="content ps-lg-3">
                  <h3>{background?.title}</h3>
                  <p>{background?.description}</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="services-list" className="services-list">
          <div className={"container shadow p-5"} data-aos="fade-up">
            <div className="text-center pb-5">
              <button
                onClick={(e) => {
                  e.preventDefault();
                  setShowSMM(1);
                  setShowBoD(0);
                }}
                className={showSMM===1 ? "me-2 team-btn" : "me-2 team-btn-border"}
              >
                {t("Executive Management")}
              </button>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  setShowSMM(0);
                  setShowBoD(1);
      
                }}
                className={showSMM ? "me-2 team-btn-border" : "me-2 team-btn"}
              >
                {t("Board of Directors")}
              </button>
            </div>
            <div className={showSMM===1 && showBoD ===0 ?"d-show":"d-none"}>
            <div className="section-header">
                  <h2>{t("Executive Management")}</h2>
                </div>
                <div className="row gy-5 text-center justify-content-center mb-5 ">
                  {smExecutives.map((member, index) => (
                    <TeamMember member={member} key={index} />
                  ))}
                </div>
                <div className="row gy-5 text-center justify-content-center">
                  {smManagers.map((member, index) => (
                    <TeamMember member={member} key={index} />
                  ))}
                </div>
            </div>
            <div className={showSMM===0 && showBoD ===1 ?"d-show":"d-none"}>
            <div className="section-header">
                  <h2>{t("Board of Directors")}</h2>
                </div>
                <div className="row gy-5 text-center justify-content-center mb-5 ">
                  {bdExecutives.map((member, index) => (
                    <TeamMember member={member} key={index} />
                  ))}
                </div>
                <div className="row gy-5 text-center justify-content-center">
                  {bdManagers.map((member, index) => (
                    <TeamMember member={member} key={index} />
                  ))}
                </div>
            </div>
          </div>
        </section>
        <section id="services-list" className="services-list bg-light">
          <div className="container" data-aos="fade-up">
            <div className="section-header">
              <h2>{t("BDF Mission, Vision and Values")}</h2>
            </div>
            <div className="row gy-5">
              <div
                className="col-lg-3 col-md-6 service-item d-flex"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <div className="icon flex-shrink-0"></div>
                <div>
                  <h4 className="title">
                    <a href="#" className="stretched-link">
                      {t("Mission")}
                    </a>
                  </h4>
                  <p className="description">{misVisValue?.mission?.mission}</p>
                </div>
              </div>
              <div
                className="col-lg-3 col-md-6 service-item d-flex"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <div className="icon flex-shrink-0"></div>
                <div>
                  <h4 className="title">
                    <a href="#" className="stretched-link">
                      {t("Vision")}
                    </a>
                  </h4>
                  <p className="description">{misVisValue?.vision?.vision}</p>
                </div>
              </div>
              <div className="col-lg-1"></div>
              <div
                className="col-lg-5 col-md-6 service-item d-flex"
                data-aos="fade-up"
                data-aos-delay="300"
              >
                <div className="icon flex-shrink-0"></div>
                <div>
                  <h4 className="title">
                    <a href="#" className="stretched-link">
                      {t("Values")}
                    </a>
                  </h4>
                  <p className="description">
                    <div className="row">
                      {misVisValue?.values?.map((val, index) => (
                        <div key={index} className="col-md-6">
                          <i className="bi bi-check-circle-fill"></i>{" "}
                          {val?.value}
                        </div>
                      ))}
                    </div>
                  </p>
                </div>
              </div>
            </div>
            <div className="row gy-5 pt-3 d-none">
              <div
                className="col-md-5 service-item d-flex"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <div className="icon flex-shrink-0"></div>
                <div>
                  <h4 className="title">
                    <a href="#" className="stretched-link">
                      {t("Purpuse")}
                    </a>
                  </h4>
                  <p className="description">{t("PurposeVal")}</p>
                </div>
              </div>
              <div className="col-md-2"></div>
              <div
                className="col-md-5 service-item d-flex"
                data-aos="fade-up"
                data-aos-delay="300"
              >
                <div className="icon flex-shrink-0"></div>
                <div>
                  <h4 className="title">
                    <a href="#" className="stretched-link">
                      {t("Value Proposition")}
                    </a>
                  </h4>
                  <p className="description">
                    <div className="row">
                        <div className="">
                          <span><i className="bi bi-check-circle-fill"></i>{" "}
                          {t("valp1")}</span>
                        </div>
                        <div className="">
                        <span><i className="bi bi-check-circle-fill"></i>{" "}
                        {t("valp2")}</span>
                      </div>
                      <div className="">
                      <span><i className="bi bi-check-circle-fill"></i>{" "}
                      {t("valp3")}</span>
                    </div>
                    </div>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="call-to-action" className="call-to-action">
          <div className="container" data-aos="fade-up">
            <div className="row justify-content-center">
              <div className="col-lg-6 text-center">
                <h3>{t("Careers")}</h3>
                <p>{t("CareerMsg")}</p>
                <NavLink className="cta-btn" to="/publications">
                  {t("Read All")}
                </NavLink>
              </div>
            </div>
          </div>
        </section>
        <PartnersSection />
      </main>
      <Footer />
      <ScrollToTop />
    </body>
  );
};
